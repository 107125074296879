import $ from "jquery";

$(function() {
    const dropzone = $('#dropzone');
    const fileInput = $('#file-input');
    const preview = $('#preview');
    const loader = $('#loader');

    dropzone.on('dragover', function(event) {
        event.preventDefault();
        dropzone.addClass('dragover');
    });

    dropzone.on('dragleave', function(event) {
        event.preventDefault();
        dropzone.removeClass('dragover');
    });

    dropzone.on('drop', function(event) {
        event.preventDefault();
        dropzone.removeClass('dragover');

        const file = event.originalEvent.dataTransfer.files[0];

        if (file.type.match(/image\/(jpeg|png|webp)/)) {
            fileInput.prop('files', event.originalEvent.dataTransfer.files);

            const reader = new FileReader();
            reader.onload = function(event) {
                preview.attr('src', event.target.result);
                preview.show();
            };
            reader.readAsDataURL(file);
            loader.show();
            dropzone.submit();
        } else {
            alert('Пожалуйста, выберите файл в формате JPEG, PNG или WebP.');
        }
    });

    fileInput.on('change', function(event) {
        const file = event.target.files[0];

        if (file.type.match(/image\/(jpeg|png|webp)/)) {
            const reader = new FileReader();
            reader.onload = function(event) {
                preview.attr('src', event.target.result);
                preview.show();
            };
            reader.readAsDataURL(file);
            loader.show();
            dropzone.submit();
        } else {
            alert('Пожалуйста, выберите файл в формате JPEG, PNG или WebP.');
        }
    });


});
